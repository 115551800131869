import React from 'react'
import './recruitment.css'
import Navbar from '../navbar/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../footer/Footer'


const Recruitment = () => {
  return (
    <div>
        <Navbar></Navbar>

        <div className='recruitment_div'>
           <div className='head_div'>
            <h1 className='rec_head'>Welcome to GEU ACM Student Chapter</h1>
            </div>
           <div className='ready_para_div'>
            <p className='ready_para'>Are you ready to dive into the world of technology and innovation while being part of a supportive community? We're thrilled to announce openings for various positions within our chapter for the session 2024-25.</p>
            <h4>Why Join GEU ACM and ACM-W?</h4>
            <ul>
                <li>
                    <h5>Networking Opportunities : </h5><p>Connect with industry professionals, fellow students, and experts in technology. Forge valuable connections that can open doors to internships, mentorship, and career opportunities.</p>
                </li>

                <li>
                    <h5>Community Engagement : </h5><p>Immerse yourself in a vibrant community that celebrates diversity, creativity, and innovation. Engage in discussions, share ideas, and contribute to projects that make a meaningful impact locally and beyond.</p>
                </li>

                <li>
                    <h5>Skill Enhancement : </h5><p>Develop a diverse skill set through hands-on experience, workshops, and collaborative projects. Whether it's honing your coding abilities, mastering design software, or refining your marketing strategies, there's ample opportunity for growth.</p>
                </li>

                <li>
                    <h5>Leadership Development : </h5><p>Take on leadership roles within the chapter and gain invaluable experience in project management, event coordination, and team leadership. Learn to effectively lead teams and navigate challenges in a supportive environment.</p>
                </li>
            </ul>

            <div>
                <h4>Benefits of Joining Each Team :</h4>

                <ul>
                    <li>
                        <h5>
                        Operations Team: 
                        </h5>
                        <p>
                        As a member of the Operations Team, you'll immerse yourself in the dynamic world of event planning, logistics, and coordination. Gain hands-on experience in managing resources effectively, from budgeting to venue selection. Learn to anticipate and address logistical challenges, ensuring the seamless execution of chapter activities. Develop essential organizational and problem-solving skills while playing a pivotal role in bringing our events to life.
                        </p>
                    </li>

                    <li>
                        <h5>
                        Design Team: 
                        </h5>
                        <p>
                        Joining the Design Team allows you to unleash your creativity and leave a lasting visual impact on our chapter's identity. From crafting eye-catching graphics for events to designing promotional materials and branding assets, you'll have the opportunity to showcase your talent and hone your design skills. Collaborate with fellow designers to brainstorm innovative concepts and transform ideas into visually stunning creations. Build a strong portfolio and elevate your design expertise in a supportive and collaborative environment.
                        </p>
                    </li>

                    <li>
                        <h5>
                        Social Media Content Creation + Production Team: 
                        </h5>
                        <p>
                        Dive into the exciting realm of content creation and production for social media platforms as part of our Social Media Content Creation + Production Team. Take the lead in crafting engaging content that resonates with our audience, from captivating posts to compelling videos and multimedia materials. Work closely with the marketing and design teams to ensure cohesive brand messaging and visually appealing content. Amplify our online presence, boost engagement, and play a vital role in shaping our chapter's digital footprint.
                        </p>
                    </li>

                    <li>
                        <h5>
                        Marketing Team: 
                        </h5>
                        <p>
                        As a member of the Marketing Team, you'll sharpen your marketing skills and gain hands-on experience in promoting chapter events, initiatives, and membership recruitment. Dive into market research, audience analysis, and campaign planning to craft targeted and impactful marketing strategies. Learn to leverage various channels effectively, from social media and email marketing to traditional outreach methods. Collaborate with cross-functional teams to develop compelling messaging and creative campaigns that drive engagement and foster community growth.
                        </p>
                    </li>

                    <li>
                        <h5>
                        Technical Team: 
                        </h5>
                        <p>
                        Join the Technical Team and harness your technical expertise to support chapter events, workshops, and projects. From website development and software maintenance to troubleshooting technical challenges, you'll play a crucial role in ensuring the smooth functioning of our technical infrastructure. Collaborate with fellow technologists to innovate and implement technical solutions that enhance the chapter's operations and deliver value to our members. Gain practical experience, expand your skill set, and make a meaningful impact in the tech community.
                        </p>
                    </li>

                    <li>
                        <h5>
                        Editorial Team: 
                        </h5>
                        <p>
                        If you have a passion for writing and storytelling, the Editorial Team offers an exciting opportunity to polish your writing skills and contribute to our chapter's content initiatives. From crafting compelling articles and blog posts to curating newsletters and publications, you'll have the platform to share your unique voice and perspective. Collaborate with fellow writers and editors to brainstorm ideas, conduct research, and bring stories to life. Develop your storytelling abilities, build a diverse portfolio of written work, and engage our audience through captivating content.
                        </p>
                    </li>
                </ul>
            </div>

            <div>
                <h4>Application Process:</h4>
             <ul className='application_list'>
                <li>Fill out the required information in the form provided.</li>
                <li>Select the position(s) you are interested in applying for.</li>
                <li>Highlight any relevant experience, skills, or qualifications that make you a suitable candidate.</li>
                <li>Submit your application before the deadline.</li>
             </ul>
             <Link to="https://forms.gle/foeXQhB3uJRMHPwMA"><button className='apply_button'> Apply!</button></Link>

             <p className='bot_para'>
             Thank you for considering joining GEU ACM and ACM-W Student Chapter! We look forward to reviewing your applications and building an exceptional team for the upcoming session. If you have any queries, feel free to contact Shivyanshu at 7017585059. Let's embark on this exciting journey together!
             </p>
            </div>
           </div>
        </div>
        <Footer></Footer>
    </div>
  )
}

export default Recruitment
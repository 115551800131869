import React from "react";
import Card1post from "./utcc.png";
import Card2post from "./aurora3.0.jpg";
import "./recentevents.css";
const Recentevents = () => {
  return (
    <div>
      <div>
        <div className="recenteventdiv">
          <h1 className="recenthead">Recent Events</h1>
        </div>
        <div className="cards_event">
          <div className="receventcard">
            <h1 className="reccardhead">The UCC 2.0</h1>
            <p className="receventparagraph">
            The Ultimate Coding Challenge 2.0, hosted by GEU ACM & ACM-W, saw 275 participants solving complex DSA problems. Guided by Mr. Ashish Garg, they tackled challenges, fueled by Domino's pizzas, to win prizes and inspire coding excellence.<br></br>
              {/* <h6 className="speakername">Speaker : Pooja Joshi</h6> */}
            </p>
            <img src={Card1post} className="card2post" alt=""></img>
          </div>

          <div className="receventcard2">
            <h1 className="reccardhead">Aurora 3.0</h1>
            <p className="receventparagraph">
            Aurora 3.0: Igniting Innovation at the GEU ACM Tech-Fest, a dynamic 3-day extravaganza from March 6th-9th, showcasing cutting-edge technology and fostering collaboration among tech enthusiasts<br></br>
              {/* <h6 className="speakername">Speaker : Harshvardhan Singh Rawat</h6> */}
            </p>
            <img src={Card2post} className="card1post" alt=""></img>
          </div>
  
        </div>
      </div>
    </div>
  );
};

export default Recentevents;

import React from 'react'
import { useState } from 'react'
import './review.css'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
const Review = () => {

  return (
    <>
    <div><Navbar></Navbar></div>
    <div className='parentdiv'>
        
        
          <div className="reviewform">
            <h1 className="reviewformhead">GEU ACM Membership Benefits</h1>
            <div>
            <p className='joining_para'>Joining the Association for Computing Machinery (ACM) offers numerous benefits for individuals interested in the field of computing and technology. Here are some of the key advantages:</p>
            </div>
            <br></br>
            <div className='benefits'>
              <ul>
                <li><h4>Networking Opportunities :</h4> 
                <p className="benefit_para">ACM provides a platform for networking with professionals, researchers, and experts in the computing industry. Members can connect with like-minded individuals, establish valuable contacts, and build relationships that can lead to collaborations, mentorship, and career opportunities.</p></li>

                <li>
                  <h4>Access to Resources and Publications : </h4>
                  <p className="benefit_para">ACM offers access to a vast array of resources, including journals, magazines, conference proceedings, and digital libraries. Members can stay updated on the latest advancements, research findings, and trends in computing through these publications, enhancing their knowledge </p>
                </li>

                <li>
                  <h4>Professional Development : </h4>
                  <p className="benefit_para">ACM organizes conferences, workshops, seminars, and webinars on various topics related to computing and technology. These events provide opportunities for members to enhance their skills, learn from industry leaders, and stay abreast of emerging technologies and best practices.</p>
                </li>

                <li>
                  <h4>Career Support : </h4>
                  <p className="benefit_para">ACM offers career development resources such as job boards, career fairs, and online career services. Members can explore job opportunities, access career advice and guidance, and receive support in advancing their careers in the computing industry.</p>
                </li>
                <li>
                  <h4>Discounts and Benefits : </h4>
                  <p className="benefit_para">ACM members enjoy discounts on conference registration fees, subscriptions to ACM publications, and access to various products and services offered by ACM partners and affiliates. These discounts can result in significant cost savings for individuals, especially students and professionals on a tight budget.</p>
                </li>
                <li>
                  <h4>Global Reach : </h4>
                  <p className="benefit_para">ACM is an international organization with members from around the world. By joining ACM, individuals can connect with professionals and researchers from diverse backgrounds and cultures, gaining valuable insights and perspectives that can enrich their understanding of computing and technology on a global scale.</p>
                </li>

                <li>
                  <h4> Project Guidance and Support :</h4>
                  <p className='benefit_para'>ACM provides resources and support for individuals interested in building mini-projects or undertaking independent research. Through local chapters, SIGs, and online communities, members can seek guidance, advice, and mentorship from experienced professionals and researchers. </p>
                </li>
                
              </ul>
            </div>
        </div>
       
    </div>
    <Footer></Footer>
    </>
  )
}

export default Review
import React from "react";
import "./eventcard.css";
import Utcc from "./eventimages/utcc.png";
import ILLimg from "./eventimages/aurora3.0.jpg";
import Cqc from './eventimages/CQC-1.png'
import Shardeum from './eventimages/shardeum.png'

const Eventcard = () => {
  return (
    <div>
      <div className="cardcontainer">
        <div className="eventcard">
          <img src={Utcc} className="eventcardimg"></img>
          <h1 className="cardheading">
            The Ultimate Coding <br></br>Challenge 2.0
          </h1>
          <p className="cardparagraph">
          The Ultimate Coding Challenge 2.0, hosted by the GEU ACM & ACM-W Student Chapters on March 12, 2024, was a monumental event, drawing 275 enthusiastic participants. Under the guidance of Mr. Ashish Garg, Assistant Professor at Graphic Era University, the competition centered on Data Structures and Algorithms (DSA) problem-solving. Participants faced four challenging questions, testing their coding prowess and analytical skills. Energized by Domino's pizzas, contestants tackled each problem with fervor, creating an electrifying atmosphere. Winners from the 6th and 4th semesters emerged, rewarded with prizes like boat airdopes, leather bags, and insulated water bottles, inspiring them to continue their coding journey with passion and zeal.
          </p>
        </div>
      </div>

      <div className="cardcontainer">
        <div className="eventcard">
          <img src={ILLimg} className="eventcardimg"></img>
          <h1 className="cardheading">
            Aurora 3.0 
          </h1>
          <p className="cardparagraph">
          Step into the future at Aurora 3.0, the exhilarating 3-day tech-fest organized by GEU ACM. From March 6th to 9th, immerse yourself in a dynamic convergence of innovation, creativity, and technology. Explore cutting-edge advancements through captivating workshops, stimulating panel discussions, and thrilling competitions. Engage with industry leaders, tech enthusiasts, and students alike as you delve into the latest trends shaping tomorrow's world. Whether you're a seasoned professional or a curious novice, Aurora 3.0 promises an unforgettable journey into the realms of digital transformation and beyond. Mark your calendars for an unforgettable experience at GEU ACM's Aurora 3.0.
          </p>
        </div>
      </div>
      

      <div className="cardcontainer">
        <div className="eventcard">
          <img src={Cqc} className="eventcardimg"></img>
          <h1 className="cardheading">
            Career Quest<br></br> Chronicles
          </h1>
          <p className="cardparagraph">
          Embarking on a journey of professional enlightenment, 'Career Quest Chronicles' unfolded on October 14th, 2023, at the Seminar Hall, Lt - 1, Lt - 2. This exceptional event brought together accomplished alumni and 4th-year seniors, who generously shared their experiences navigating the challenges of interviews, technical assessments, and landing positions in top-tier companies like Microsoft, Google, and Atlassian. Attendees were treated to a treasure trove of insider tips and strategies, offering profound insights into the interview process, workplace culture, and the trajectory to career growth. 'Career Quest Chronicles' was an immersive experience, weaving a tapestry of wisdom, inspiration, and professional success.
          </p>
        </div>
      </div>
      

      <div className="cardcontainer">
        <div className="eventcard">
          <img src={Shardeum} className="eventcardimg"></img>
          <h1 className="cardheading">
            Blockchain Workshop<br></br>By Shardeum
          </h1>
          <p className="cardparagraph">
          Discover the transformative potential of blockchain technology and Shardeum at our exclusive seminar, meticulously organized by GEU ACM. Delved into the fundamentals of blockchain, distributed ledger technology, and smart contracts. Explored real-world use cases, engaged in hands-on exercises, and participated in an interactive Q&A session. This two-hour event on October 13, 2023, was designed for students across diverse disciplines keen on exploring the cutting-edge realm of blockchain technology. With comprehensive workshop materials provided, attendees experienced an enriching learning experience. Elevated their understanding of blockchain's impact and contributed to interdisciplinary learning. Joined us in shaping the future of technology.
          </p>
        </div>
      </div>



     
    </div>
  );
};

export default Eventcard;

// A hands-on workshop titled "Adobe Illustrator: Beginners to
// Advanced" was organized by GEU ACM, GEU-ACMW student chapters. The
// workshop was taken by Harsh Vardhan Singh Rawat and organized by
// Anushka Rawat, Taha Ali, Shivang Yadav and Shivyanshu Saini under
// the guidance of Assistant Professor Sarishma Dangi, Dept. of CSE.
// The workshop was organized on 1st April, 2023 at New Lab 1 and New
// Lab 2 at CSIT block, Graphic Era University. The workshop
// witnessed the participation of nearly 80 students who learned the
// basics of adobe illustrator and promised to further develop their
// skills by working on advanced skills and projects. Adobe
// Illustrator workshop is a great way for individuals and
// organizations to enhance their design skills and create
// professional-grade graphics for various purposes.

// The seminar featured an expert speaker Ms. Pooja Joshi who is a
//               multiple award winning professionals with rich experience of 23
//               years in the areas of Academia, Channel Management and
//               Training.She highlighted that a cyber attack takes place worldwide
//               in every 39 seconds resulting in the high demand for cybersecurity
//               professionals in industries such as finance, healthcare,
//               government, and technology. The importance of acquiring a strong
//               foundation in computer science, networking, and programming was
//               emphasized as a starting point for a successful career in
//               cybersecurity. The speaker stressed the significance of obtaining
//               relevant certifications such as Network Defence Essentials (NDE),
//               Ethical Hacking Essentials (EHE) and Certified Ethical Hacker
//               (CEH) to enhance one's knowledge and credibility in the field.

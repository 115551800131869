import React from "react";
import "./officers.css";
import { Link } from "react-router-dom";
import Instalogo from "./instagram.svg";
import Linkedinlogo from "./linkedin-in.svg";
import Ishika from "./Ishika.jpg";
import Amaan from "./malvika.png";
import Arnav from "./Prashuk.png";
import Shivyanshu from "./shivyanshu.png";
import Shivang from "./yashtyagi.png";
import Parinay from './parinay.png';

const Officers = () => {
  return (
    <div>
      <div className="officersdiv">
      <div className="officersHead">
          <h1>Office Bearers (2023-24)</h1>
      </div>
        <div className="officerscarousel">
          <section className="container">

          <div className="card">
              <div className="image">
                <img src={Ishika} alt="" />
              </div>
              <h2>Ishika Gupta</h2>
              <p>ADVISOR</p>
              <div className="socialmediaoff">
               <Link to="https://www.instagram.com/_.okayish._/" className="scmofficon"><img src={Instalogo} ></img></Link> 
                <div className="scmvloff"></div>
               <Link to="https://www.linkedin.com/in/ishika-gupta-09a187213/" className="scmofficon" ><img src={Linkedinlogo} ></img></Link> 
              </div>
            </div>


            <div className="card">
              <div className="image">
                <img src={Shivyanshu} alt="" />
              </div>
              <h2>Shivyanshu Saini</h2>
              <p>CHAIRPERSON</p>
              <div className="socialmediaoff">
               <Link to="https://www.instagram.com/shivyanshu_saini_suryavanshi/" className="scmofficon"><img src={Instalogo} ></img></Link> 
                <div className="scmvloff"></div>
               <Link to="https://www.linkedin.com/in/shivyanshu-saini/" className="scmofficon" ><img src={Linkedinlogo} ></img></Link> 
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={Amaan} alt="" />
              </div>
              <h2>Malvika Bisht</h2>
              <p>VICE-CHAIR</p>
              <div className="socialmediaoff">
               <Link to="https://www.instagram.com/malvika_2701/" className="scmofficon"><img src={Instalogo} ></img></Link> 
                <div className="scmvloff"></div>
               <Link to="https://www.linkedin.com/in/malvi27/" className="scmofficon" ><img src={Linkedinlogo} ></img></Link> 
              </div>
            </div>
          
            <div className="card">
              <div className="image">
                <img src={Arnav} alt="" />
              </div>
              <h2>Prashuk Jain</h2>
              <p>SECRETARY</p>
              <div className="socialmediaoff">
               <Link to="https://www.instagram.com/_prashukjain._/" className="scmofficon"><img src={Instalogo} ></img></Link> 
                <div className="scmvloff"></div>
               <Link to="https://www.linkedin.com/in/prashuk-jain-333862176/" className="scmofficon" ><img src={Linkedinlogo} ></img></Link> 
              </div>
            </div>

            <div className="card">
              <div className="image">
                <img src={Parinay} alt="" />
              </div>
              <h2>Parinay Panwar</h2>
              <p>WEBMASTER</p>
              <div className="socialmediaoff">
               <Link to="https://www.instagram.com/justparinay/" className="scmofficon"><img src={Instalogo} ></img></Link> 
                <div className="scmvloff"></div>
               <Link to="https://www.linkedin.com/in/parinay-panwar-76b3831b9/" className="scmofficon" ><img src={Linkedinlogo} ></img></Link> 
              </div>
            </div>

            <div className="card">
              <div className="image">
                <img src={Shivang} alt="" />
              </div>
              <h2>Yash Tyagi</h2>
              <p className="membch">MEMBERSHIP-CHAIR</p>
              <div className="socialmediaoff">
               <Link to="https://www.instagram.com/believer_yash/" className="scmofficon"><img src={Instalogo} ></img></Link> 
                <div className="scmvloff"></div>
               <Link to="https://www.linkedin.com/in/yash-tyagi-964a68221/" className="scmofficon" ><img src={Linkedinlogo} ></img></Link> 
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Officers;

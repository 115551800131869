import React from "react";
import "./instructor.css";
import instimg from "./akshitamam.png";
import hodimg from "./hodsir.png";
const Instructor = () => {
  return (
    <div>
      <div className="maindiv">
        <div className="instructordiv">
          <div className="ourinst">
            <h1>Our Instructor</h1>
            <div className="instimgdiv">
              <img className="instimg" src={instimg}></img>
            </div>
            <h3 className="instname">Ms. Akshita Patwal</h3>
            <h4 className="instdesg">Assistant Professor</h4>
            <h4 className="instdesg">Dept. of CS&E</h4>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Instructor;

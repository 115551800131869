import React from "react";
import "./upcomingevent.css";
import FDPimg from "./rd.png";
const UpcomingEventCard = () => {
  return (
    <div>
     <div className="upcarddiv">
      <div className="upeventcard">
        <img src={FDPimg} className="upcardimg"></img>
        <h1 className="eventcardtext">Upcoming Event</h1>
        <h1 className="eventcardtext1">Recruitment Drive <br></br>For Session 2024-25</h1>
        <p className="eventcardtext3">Join us on April 6th, 2024, as GEU ACM hosts its eagerly awaited recruitment drive, offering exciting opportunities to join our dynamic team. Don't miss your chance to be a part of shaping the future of technology with GEU ACM</p>
        <p className="eventcardtext2">
          Contact : Shivyanshu(7017585059) <br></br>
          Registrations : Free <br></br>
          Registrartion Link : <a href="https://forms.gle/foeXQhB3uJRMHPwMA">https://forms.gle/foeXQhB3uJRMHPwMA</a>
        </p>
      </div>
     </div>
    </div>
  );
};

export default UpcomingEventCard;

import React from 'react'
import './gallery.css'
import Navbar from '../navbar/Navbar'
import Img1 from './images/IMG_20240306_100113.jpg';
import Img2 from './images/IMG_9969.png'
import Img3 from './images/IMG_0028.png'
import Img4 from './images/IMG_0083.png'
import Img5 from './images/IMG_0156.png'
import Img6 from './images/IMG_0183 (1).png'
import Img7 from './images/IMG_0596.png'
import Img8 from './images/IMG_0618.png'
import Img9 from './images/IMG_0661.png'
import Img10 from './images/IMG_0761.png'
import Img11 from './images/IMG_0762.png'
import Img12 from './images/IMG_8388.png'
import Img13 from './images/IMG_8358.png'
import Img14 from './images/IMG_8478.png'
import Img15 from './images/IMG_0024 (1).png'
import Img16 from './images/ms.png'
import Img17 from './images/sm.png'
import Footer from '../footer/Footer';

const Gallery = () => {
  return (
    <div >
        <Navbar></Navbar>
        <div className='main_div'>
            <h1 className='eghead'>Event Gallery</h1>
            <div className='gallery_image_container'>
                <img className='galimg' src={Img1} alt="" />
                <img className='galimg' src={Img2} alt="" />
                <img className='galimg' src={Img3} alt="" />
                <img className='galimg' src={Img4} alt="" />
                <img className='galimg' src={Img5} alt="" />
                <img className='galimg' src={Img6} alt="" />
                <img className='galimg' src={Img7} alt="" />
                <img className='galimg' src={Img8} alt="" />
                <img className='galimg' src={Img9} alt="" />
                <img className='galimg' src={Img10} alt="" />
                <img className='galimg' src={Img11} alt="" />
                {/* <img className='galimg vertimage' src={Img12} alt="" />*/}
                {/* <img className='galimg vertimage' src={Img13} alt="" />  */}
                <img className='galimg' src={Img15} alt="" />
                <img className='galimg' src={Img14} alt="" />
                <img className='galimg' src={Img17} alt="" />
                <img className='galimg' src={Img16} alt="" />
                
            </div>
        </div>
        <Footer></Footer>
    </div>
  )
}

export default Gallery